

require(['require.config'], function(){
	require(['core'], function(){
		require(['app', 'less!css/style'], function(app){
			window.CKEDITOR_BASEPATH = app.getPath('/js/components/ckeditor/ckeditor/');
			app.start();
		});
	});
});
define("bootstrap", function(){});


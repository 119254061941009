require.config({
	waitSeconds: 30,
	paths: {
		"css": "../css",
		"pageassets": "../../../pageassets/src",
		"underscore": "lib/underscore/underscore",
		"underscore.string": "lib/underscore/underscore.string",
		"jquery": "lib/jquery/jquery",
		"jqueryui": "lib/jquery-ui/jquery-ui",
		"chosen": "lib/chosen/chosen.jquery",
		"moment": "lib/moment/moment",
		"moment-timezone": "lib/moment-timezone/moment-timezone-with-data-2023-2033",
		"mustache": "lib/mustache/mustache",
		"jlinq": "lib/jlinq/jlinq",
		"spectrum": "lib/spectrum/spectrum",
		"datepicker": "lib/datepicker/datepicker",
		"enDatePicker": "lib/enDatePicker/enDatePicker",
		"enTooltip": "lib/enTooltip/enTooltip",
		"editinline": "lib/editinline/editinline",
		"tableselect": "lib/tableselect/jquery.tableselect",
		"eawidget": "lib/eawidget/eawidget",
		"highchartsCore": "components/highcharts/highcharts.src",
		"highchartsExporting": "components/highcharts/modules/exporting.src",
		"highcharts": "components/highcharts/modules/export-csv",
		"backbone": "lib/backbone/backbone.all",
		"backboneCore": "lib/backbone/backbone",
		"backboneSubRoute": "lib/backbone/backbone.subroute",
		"backboneViewMaster": "lib/backbone/backbone.viewmaster",
		"backboneCollectionView": "lib/backbone/backbone.collectionview",
		"backboneEnPaginatedCollection": "lib/backbone/backbone.enpaginatedcollection",
		"backboneEnPaginatedCollectionView": "lib/backbone/backbone.enpaginatedcollectionview",
		"backboneEnView": "lib/backbone/backbone.enview",
		"backboneEnCollectionView": "lib/backbone/backbone.encollectionview",
		"backboneEnSearchCollectionView": "lib/backbone/backbone.ensearchcollectionview",
		"text": "lib/require/text",
		"async": "lib/require/async",
		"json": "lib/require/json",
		"worker": "lib/require/worker",
		"ckeditorCore": "components/ckeditor/ckeditor/ckeditor",
		"ckeditorPlugins": "components/ckeditor/cke-plugins/cke-plugins",
		"ckeditor": "components/ckeditor/ckeditor/adapters/jquery",
		"ace": "components/ace/ace",
		"htmlparser": "lib/htmlparser/htmlparser",
		"html-to-text": "lib/html-to-text",
		"jquery-sorter": "lib/jquery/jquery.sorter",
		"jquery-tab": "lib/jquery/jquery.tab"
	},
	
	map: {
		'*': {
			'less': 'lib/require/less',
			"cke-plugins": "components/ckeditor/cke-plugins/cke-plugins",
		}
	},
	
	shim: {
		htmlparser: {
			exports: 'exports'
		},
		ckeditor: ['jquery', 'ckeditorPlugins', 'ckeditorCore'],
		highcharts: {
			deps: ['highchartsCore', 'highchartsExporting'],
			exports: 'Highcharts'
		},
		highchartsCore: {
			deps: ['jquery'],
			exports: 'Highcharts'
		},
		highchartsExporting: {
			deps: ['highchartsCore'],
			exports: 'Highcharts'
		},
		jqueryui: {
			deps: ['jquery'],
			exports: '$'
		},
		chosen: {
			deps: ['jquery'],
			exports: 'jQuery.fn.chosen'
		},
		tableselect: {
			deps: ['jquery', 'jlinq'],
			exports: '$'
		},
		eawidget: {
			deps: ['jqueryui'],
			exports: '$'
		},
	}
});
define("require.config", function(){});

